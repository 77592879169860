<template>

  <!-- 初始页面 -->
   <!-- 校园筛查  筛查计划管理 新增学生 -->
  <div class="page">
    <div class="header-container">
      <div class="header-section">
        <div class="button-section">
          <!-- <el-button type="primary" size="mini" round @click="goTo('/detection/importStudent')">批量导入学生</el-button> -->
        <el-button type="primary" size="mini" round @click="goTo('/detection/addStudentInfo')">新增</el-button>
        <el-button v-if="roleType === 9" type="disabled" class="re_button" size="mini" round @click="goTo('/detection/recycle')">回收站</el-button>
         </div>
      </div>
    </div>
    <div class="importList">
      <div class="schoolList">
        <el-link
          type="success"
          :underline="false"
          @click="goTo('/detection/schoolList')"
          style="color: #333"
        >
          已导入学生的学校列表
        </el-link>
      </div>

      <div class="studentList">
        <el-link
          :underline="false"
          @click="goTo('/detection/student')"
        >
          已导入学生列表
        </el-link>
      </div>
    </div>

    <!-- 选择学校-->
    <div class="select">
      <el-select v-model="schoolId" size="mini" filterable :filter-method="searchSchool" placeholder="请选择学校" @change="changeSchool">
        <el-option
          v-for="item in schoolOptions"
          :key="item.value"
          :label="item.label"
          :value="{value:item.value,label:item.label}"
        ></el-option>
      </el-select>
    </div>

    <div class="col">
      <div class="grid-content" v-for="(item,index) in tablePageData" :key="index">
        <div class="class-name">
          <div class="schoolName">{{item.schoolName}}</div>
          <div class="street" >
            <p class="street-title">{{ item.street }}</p>
            </div>
          <el-link type="success" class="check" @click="goTo('/detection/class',{'schoolId':item.schoolId,'schoolName':item.schoolName})">查看</el-link>
        </div>
        <div class="classNumber">
          <span class="classSpan">已导入班级：<span class="numberOfPeople">{{item.classNums}}</span></span>
          <span class="stuSpan">已导入学生：<span class="numberOfPeople">{{item.studentNums}}</span></span>
        </div>
        <div class="planNumber">
          <span>已导入筛查计划：<span class="numberOfPeople">{{item.screeningNums}}</span></span>
        </div>
      </div>
    </div>
    <div class="table-page">
      <pagination :current-page="startPage" :total="totalPage"  @currentChange="handleCurrentChange" @sizeChange="sizeChange"></pagination>
    </div>

  </div>
</template>
<script>
import selectService from '@/globals/service/detection/select.js'
import schoolService from '@/globals/service/detection/school.js'
import storage from '@/globals/storage/index.js'

export default {
  data () {
    return {
      roleType: null,
      schoolId: '',
      schoolOptions: [],
      total: 0,
      totalPage: 0,
      startPageSchool: 1,
      pageSizeSchool: 50,
      startPage: 1, // 当前页码
      pageSize: 9,
      schoolColList: [],
      tablePageData: [],
      multipleSelection: [],
      batchNum: 0
    }
  },
  created () {
    this.roleType = Number(storage.get('roleType'))
  },
  mounted () {
    this.getSchoolSelect()
    this.getListData()
  },
  methods: {
    sizeChange (val) {
      this.pageSize = val
      this.dataPages()
    },
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    dataPages () {
      const schoolColList = this.schoolColList
      const startPage = (this.startPage - 1) * this.pageSize
      if (this.total) {
        this.tablePageData = schoolColList.slice(startPage, startPage + this.pageSize)
      } else {
        this.total = 0
        this.tablePageData = []
      }
      this.totalPage = Math.ceil(this.total / this.pageSize)
    },
    searchSchool (row) {
      schoolService.search({ name: row }).then(res => {
        const schoolList = res.data || []
        const schoolOptions = []
        schoolList.forEach(item => {
          schoolOptions.push({
            value: item.id,
            label: item.schoolName
          })
        })
        this.schoolOptions = schoolOptions
      })
    },
    handleCurrentChange (val) {
      this.startPage = val
      this.dataPages()
    },
    getListData () {
      schoolService.import({}).then(res => {
        this.schoolColList = res.data
        this.total = res.data.length
        this.totalPage = res.data.totalPage
        this.dataPages()
      })
    },
    getSchoolSelect () {
      selectService.school({ startPage: this.startPageSchool, pageSize: this.pageSizeSchool }).then(res => {
        const schoolList = res.data.schoolList
        const schoolOptions = []
        schoolList.forEach(item => {
          schoolOptions.push({
            value: item.id,
            label: item.schoolName
          })
        })
        this.schoolOptions = schoolOptions
      })
    },
    changeSchool (params) {
      const { value, label } = params
      this.$router.push({ path: '/detection/class', query: { schoolId: value, schoolName: label } })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'single-row'
      } else {
        return 'double-row'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.header-container{
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;
  .header-section{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .route-name{
      line-height: 43px;
      font-size: 12px;
      color: #333;
    }
    .button-section{
      display: flex;
      align-items: center;
    }
  }
}
.page {
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  background: #fff;
  text-align: center;
  min-height: 100%;
  padding-bottom: 20px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #CCCCCC;
    font-size: 15px;
    font-weight: 500;
    color: #51B25F;

    span {
      border-left: 2px solid;
      padding: 0 8px;
      box-sizing: border-box;
    }
  }

  .select {
    text-align: left;
    .el-input,.el-button,.el-select{
      margin-top: 5px;
      margin-bottom: 5px;
    }
    select {
      width: 208px;
      height: 33px;
      border-radius: 4px;
      border: 1px solid #CCCCCC;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      outline: none;
      margin-right: 8px;
    }

    select:last-child {
      width: 250px;
    }
  }
}

.el-button--info {
  background-color: #CCCCCC;
  color: red;
}

.importList {
  display: flex;
  height: 20px;
  margin: 10px 0 16px 0;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #CCCCCC;
}

.schoolList, .studentList {
  display: flex;
  float: left;
  padding-right: 40px;
}

  .col {
    width: 100%;
    padding: 20px 0px;
    display: flex;
    flex-wrap: wrap;
  }
  .grid-content {
    min-width: 460px;
    max-width: 30%;
    height: 150px;
    border: 1px solid #CCCCCC;
    background-color: white;
    border-radius: 4px;
    margin-right: 16px;
    margin-bottom:14px;
    min-height: 36px;
    text-align: left;
    margin: 10px 15px 0;
    padding: 5px;
    color:#333;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  .class-name{
    display: flex;
    padding: 12px 16px;
    align-items: baseline;
    min-height: 40px;
  }
  .schoolName {
    color: #333333;
    min-width: 81px;
    max-width: 200px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    padding-right: 12px;
  }

  .street {
    text-align: left;
    flex-grow: 1;
    .street-title{
      color: #999999;
      font-size: 14px;
      line-height: 1.2;
      max-width: 180px;
      width: 180px;
      overflow:hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
    }
  }

  .check{
    color: #4DAF5A;
    display: inline-block;
    margin-left: 10px;
    white-space: nowrap;
  }
  .classNumber {
    display: flex;
    padding: 12px 16px;
  }
  .stuSpan{
    text-align: center;
  }
  .classSpan, .stuSpan{
    width: 50%;
    text-align: left;
  }

  .planNumber {
    display: flex;
    padding: 12px 16px;
  }

  .numberOfPeople {
    color: #006BE3;
  }

</style>
